import Vue from 'vue'

// Munio Components
import Affix from './Affix.vue'
import Card from './Card.vue'
import CircularProgress from './CircularProgress.vue'
import CompanyCreatorModal from './CompanyCreatorModal.vue'
import CompanySelectorModal from './CompanySelectorModal.vue'
import CompanySearch from './CompanySearch/index.vue'
import Course from './Course.vue'
import CourseSelector from './CourseSelector/index.vue'
import CourseSelectorModal from './CourseSelector/Modal.vue'
import CSRF from './CSRF.vue'
import Icon from './Icon.vue'
import Date from './Date.vue'
import DatePicker from './DatePicker.vue'
import List from './list/List.vue'
import Menu from './Menu.vue'
import MenuItem from './MenuItem.vue'
import Modal from './Modal.vue'
import Quantity from './Quantity.vue'
import ShopProduct from './ShopProduct/ShopProduct.vue'
import Secret from './Secret.vue'
import Toolbar from './Toolbar/index.vue'
import User from './User.vue'
import UserSelector from './UserSelector/index.vue'

// m-*
import Panels from './Panel/Panels.vue'
import Panel from './Panel/Panel.vue'
import Select from './Select.vue'

// Material Components
import MdlButton from './mdl/Button.vue'
import MdlCheckbox from './mdl/Checkbox.vue'
import MdlChip from './mdl/Chip.vue'
import MdlDataTable from './mdl/DataTable.vue'
import MdlDataTableHeader from './mdl/DataTableHeader.vue'
import MdlDataTableRow from './mdl/DataTableRow.vue'
import MdlDetails from './mdl/Details.vue'
import MdlDivider from './mdl/Divider.vue'
import MdlFile from './mdl/File.vue'
import MdlList from './mdl/List.vue'
import MdlListItem from './mdl/ListItem.vue'
import MdlPaginator from './mdl/Paginator.vue'
import MdlPhonenumber from './mdl/Phonenumber.vue'
import MdlProgressBar from './mdl/ProgressBar.vue'
import MdlRadio from './mdl/Radio.vue'
import MdlSearchbar from './mdl/Searchbar.vue'
import MdlSlider from './mdl/Slider.vue'
import MdlSpinner from './mdl/Spinner.vue'
import MdlStepper from './mdl/Stepper.vue'
import MdlSwitch from './mdl/Switch.vue'
import MdlTab from './mdl/Tab.vue'
import MdlTabs from './mdl/Tabs.vue'
import MdlTextfield from './mdl/Textfield.vue'
import MdlTextfieldCurrency from './mdl/TextfieldCurrency.vue'
import MdlTextfieldI18n from './mdl/TextfieldI18n.vue'

Vue.component('affix', Affix)
Vue.component('card', Card)
Vue.component('circular-progress', CircularProgress)
Vue.component('company-creator-modal', CompanyCreatorModal)
Vue.component('company-selector-modal', CompanySelectorModal)
Vue.component('company-search', CompanySearch)
Vue.component('course', Course)
Vue.component('course-selector', CourseSelector)
Vue.component('course-selector-modal', CourseSelectorModal)
Vue.component('csrf', CSRF)
Vue.component('icon', Icon)
Vue.component('b-menu', Menu)
Vue.component('b-menu-item', MenuItem)
Vue.component('list', List)
Vue.component('date', Date)
Vue.component('date-picker', DatePicker)
Vue.component('modal', Modal)
Vue.component('quantity', Quantity)
Vue.component('shop-product', ShopProduct)
Vue.component('secret', Secret)
Vue.component('toolbar', Toolbar)
Vue.component('user', User)
Vue.component('user-selector', UserSelector)

Vue.component('m-panels', Panels)
Vue.component('m-panel', Panel)
Vue.component('m-select', Select)

Vue.component('mdl-button', MdlButton)
Vue.component('mdl-checkbox', MdlCheckbox)
Vue.component('mdl-chip', MdlChip)
Vue.component('mdl-details', MdlDetails)
Vue.component('mdl-divider', MdlDivider)
Vue.component('mdl-file', MdlFile)
Vue.component('mdl-table', MdlDataTable)
Vue.component('mdl-table-header', MdlDataTableHeader)
Vue.component('mdl-table-row', MdlDataTableRow)
Vue.component('mdl-list', MdlList)
Vue.component('mdl-list-item', MdlListItem)
Vue.component('mdl-paginator', MdlPaginator)
Vue.component('mdl-phonenumber', MdlPhonenumber)
Vue.component('mdl-progressbar', MdlProgressBar)
Vue.component('mdl-radio', MdlRadio)
Vue.component('mdl-searchbar', MdlSearchbar)
Vue.component('mdl-slider', MdlSlider)
Vue.component('mdl-spinner', MdlSpinner)
Vue.component('mdl-stepper', MdlStepper)
Vue.component('mdl-switch', MdlSwitch)
Vue.component('mdl-tab', MdlTab)
Vue.component('mdl-tabs', MdlTabs)
Vue.component('mdl-textfield', MdlTextfield)
Vue.component('mdl-textfield-currency', MdlTextfieldCurrency)
Vue.component('mdl-textfield-i18n', MdlTextfieldI18n)
