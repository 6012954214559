<template>
  <form v-if="username" class="flex flex-col flex-auto justify-between" @submit.prevent="reset">
    <Profile />

    <div>
      <mdl-textfield
        ref="password"
        class="block"
        type="password"
        v-model="password"
        v-autofocus
        :error="passwordError"
        :floatingLabel="trans('Password')"
      />

      <mdl-textfield
        ref="passwordRepeat"
        class="block"
        type="password"
        v-model="passwordRepeat"
        :error="passwordRepeatError"
        :floatingLabel="trans('Repeat password')"
      />
    </div>

    <div class="action-bar">
      <mdl-button type="submit" :raised="isValid" primary :disabled="!isValid || isLoading">
        {{ trans('Log in') }}
      </mdl-button>

      <mdl-button @click="cancel">{{ trans('Cancel') }}</mdl-button>
    </div>
  </form>

  <div v-else class="action-bar">
    <mdl-button @click="cancel">
      {{ trans('Back') }}
    </mdl-button>
  </div>
</template>

<script>
import Profile from './Profile.vue'

export default {
  components: {
    Profile,
  },

  data() {
    return {
      password: '',
      passwordError: null,
      passwordRepeat: '',
      passwordRepeatError: null,
    }
  },

  methods: {
    cancel() {
      this.$store.commit('navigate', 'login')
    },

    reset() {
      if (this.isValid) {
        this.$store.dispatch('reset', this.password)
      }
    },
  },

  computed: {
    isLoading() {
      return this.$store.state.loading
    },

    isValid() {
      return this.password.length >= 5 && this.password === this.passwordRepeat
    },

    resetToken() {
      return this.$store.state.resetToken || {}
    },

    username() {
      return this.resetToken.email || this.resetToken.number
    },
  },
}
</script>
