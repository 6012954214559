import Wrapper from '@/munio/vue/Wrapper.js'

Munio.ApiManager = class extends Wrapper {
  constructor(selector, client) {
    super(selector, { client })
  }

  component() {
    if (this.data.client) {
      return import('./Client.vue')
    }

    return import('./Clients.vue')
  }
}
