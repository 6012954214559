class OAuth {
  get basePath() {
    return `/api/oauth`
  }

  get clients() {
    return new OAuthClients()
  }

  client(id) {
    return new OAuthClient(id)
  }

  scopes() {
    return Munio.$http.get(`${this.basePath}/scopes`)
  }
}

class OAuthClients {
  get basePath() {
    return `/api/oauth/clients`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  store(client) {
    return Munio.$http.post(this.basePath, client)
  }
}

class OAuthClient {
  clientId

  constructor(clientId) {
    this.clientId = clientId
  }

  get basePath() {
    return `/api/oauth/clients/${this.clientId}`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  destroy() {
    return Munio.$http.delete(this.basePath)
  }

  update(data) {
    return Munio.$http.put(this.basePath, data)
  }

  get tokens() {
    return new OAuthClientTokens(this.clientId)
  }

  get webhooks() {
    return new OAuthClientWebhooks(this.clientId)
  }
}

class OAuthClientTokens {
  clientId

  constructor(clientId) {
    this.clientId = clientId
  }

  get basePath() {
    return `/api/oauth/clients/${this.clientId}/tokens`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  create(data) {
    return Munio.$http.post(this.basePath, data)
  }

  destroy(id) {
    return Munio.$http.delete(`${this.basePath}/${id}`)
  }
}

class OAuthClientWebhooks {
  clientId

  constructor(clientId) {
    this.clientId = clientId
  }

  get basePath() {
    return `/api/oauth/clients/${this.clientId}/webhooks`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  set(name, url) {
    return Munio.$http.post(`${this.basePath}/${name}`, { url })
  }

  test(name) {
    return Munio.$http.post(`${this.basePath}/${name}/test`)
  }

  remove(name) {
    return Munio.$http.delete(`${this.basePath}/${name}`)
  }
}

export default OAuth
